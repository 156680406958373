<template>
    <div v-if="dataObject.hasNodeData" ref="listEl" class="o365-treelist"
        @scroll.passive="handleScroll">
        <div class="hstack">
            <slot name="filter"></slot>
            <OFieldFilter :columnName="field" :filterObject="dataObject.filterObject" hideColumn :placeHolder="searchPlaceholder" :autoSearchDebounce="500" />
        </div>
        <slot name="aboveList"></slot>
        <div class="o365-treelist-viewport"
            :class="{
                'indent-lines-on-hover': indentLines === 'hover',
                'indent-lines': indentLines === 'always',
            }"
            :style="{
                'min-height': totalSize + 'px',
            }">
            <template v-for="scrollRow in scrollData">
                <div class="o365-treelist-item" @click="() => handleRowClick(scrollRow.item)"
                    :class="{ 'acitve': !disableActiveStyles && scrollRow.item?.current}" @contextmenu="e => onContextMenu(e, scrollRow.item)"
                    @vue:updated="(vnode) => updateHeight(scrollRow, vnode)" @vue:mounted="(vnode) => updateHeight(scrollRow, vnode)"
                    :style="{
                        'top': scrollRow.pos + 'px',
                        'height': scrollRow.itemSize + 'px',
                        'transition': scrollRow.transition,
                    }">
                    <div class="o365-treelist-item-indent" :style="{'width': scrollRow.item.level * indent + 'px'}">
                        <div v-for="i in getRange(scrollRow.item?.level??0)" class="o365-treelist-item-indent-guide" 
                            :style="{
                                'width': indent/2 + 'px',
                                'margin-right': indent/2 + 'px'
                            }">
                            {{ INVISIBLE_CHAR }}
                        </div>
                    </div>
                    <template v-if="scrollRow.item == null || scrollRow.item.isLoading">
                            <span>
                            </span>
                            <span class="spinner-border spinner-border-sm text-primary me-2" role="status"></span>
                            {{ $t('Loading') }}...
                    </template>
                    <template v-else>
                        <span class="o365-group-expand me-1 p-2" :role="scrollRow.item.hasNodes ? 'button' : undefined" @click="e => handleExpandClick(e, scrollRow.item)">
                            <i v-if="scrollRow.item.hasNodes" class="bi" :class="scrollRow.item.expanded ? 'bi-chevron-down' : 'bi-chevron-right'" style="-webkit-text-stroke: 1px;"></i>
                            <i v-else class="bi bi-dot text-muted"></i>
                        </span>
                        <slot :row="scrollRow.item" :scrollItem="scrollRow">
                            <span class="text-truncate" :title="getDisplayValue(scrollRow.item)">
                                {{ getDisplayValue(scrollRow.item) }}
                            </span>
                        </slot>
                        <div v-if="$slots.icons" class="hover-icons">
                            <slot name="icons" :row="scrollRow.item"></slot>
                        </div>
                    </template>
                </div>
            </template>
            <ODropdown v-if="!noContextMenu" ref="dropdownRef" virtual :target-ref="virtualTarget" :closeOnMouseLeave="autoCloseContextMenu">
                <template #dropdown="{container, close}">
                    <div class="dropdown-menu shadow show" :ref="container">

                        <template v-if="$slots.contextmenuTop">
                            <div class="dropdown-divider"></div>
                            <slot name="contextmenuTop" :row="currentContextMenuRow" :close="close"></slot>
                        </template>
                        <slot name="contextMenu" :row="currentContextMenuRow" :close="close">
                            <button v-if="currentContextMenuRow.expanded" class="dropdown-item"
                                @click="() => { currentContextMenuRow.collapse(); close(); }">
                                <i class="bi bi-caret-up me-1"></i>
                                {{$t('Collapse')}}
                            </button>
                            <button v-else class="dropdown-item"
                                @click="() => { currentContextMenuRow.expand(); close(); }">
                                <i class="bi bi-caret-down me-1"></i>
                                {{$t('Expand')}}
                            </button>

                            <template v-if="currentContextMenuRow.expanded">
                                <button class="dropdown-item"
                                    @click="() => { currentContextMenuRow.details.forEach(detail => detail.expand()); close(); }"
                                    :title="$t('Expand all direct children rows')">
                                    <i class="bi bi-caret-down-fill me-1"></i>
                                    {{$t('Expand All')}}
                                </button>
                                <button class="dropdown-item"
                                    @click="() => { currentContextMenuRow.details.forEach(detail => detail.collapse()); close(); }"
                                    :title="$t('Collapse all direct children rows')">
                                    <i class="bi bi-caret-up-fill me-1"></i>
                                    {{$t('Collapse All')}}
                                </button>
                            </template>
                        </slot>
                        <template v-if="$slots.contextmenuBottom">
                            <div class="dropdown-divider"></div>
                            <slot name="contextmenuBottom" :row="currentContextMenuRow" :close="close"></slot>
                        </template>
                    </div>
                </template>
            </ODropdown>
        </div>  
    </div>
</template>

<script setup lang="ts">
import type { DataObject } from 'o365-dataobject';
import { useDataObjectEventListener, useVirtualScroll2 } from 'o365-vue-utils';
import { ODropdown } from 'o365-ui-components';
import { OFieldFilter } from 'o365-filter-components';
import { ref, computed, onMounted } from 'vue';

export interface IProps {
    dataObject: DataObject
    /** The approximately median item height */
    itemHeight?: number,
    indent?: number,
    disableActiveStyles?: boolean,

    field?: string,
    idField?: string,
    parentField?: string,
    idPathField?: string,

    loadDataObject?: boolean,
    onNodeClick?: (pItem: any) => void,
    noContextMenu?: boolean,
    autoCloseContextMenu?: boolean,
    searchPlaceholder?: string,
    indentLines?: 'none' | 'hover' | 'always',
};
export interface IEmits {
    (e: 'nodeClick', pNode: any): void
}

const props = withDefaults(defineProps<IProps>(), {
    itemHeight: 34,
    indent: 24,
    indentLines: 'hover'
});
const emit = defineEmits<IEmits>();

const INVISIBLE_CHAR = '\u200E';
const listEl = ref<HTMLElement>();
const dropdownRef = ref<InstanceType<typeof ODropdown>>();
const currentContextMenuRow = ref(null);
const cX = ref(0);
const cY = ref(0);

if (!props.dataObject.hasNodeData) {
    props.dataObject.nodeData.addConfiguration({
        type: 'hierarchy',
        idField: props.idField,
        parentField: props.parentField,
        idPathField: props.idPathField,
    });
    props.dataObject.nodeData.enable();
}

const sourceData = computed(() => props.dataObject.data);

const rowHeights = new Map<string, number>();
useDataObjectEventListener(props.dataObject, 'DataLoaded', () => {
    rowHeights.clear();
});

const { scrollData, handleScroll, totalSize} = useVirtualScroll2<any>({
    data: sourceData,
    itemSize: +props.itemHeight,
    container: listEl,
    buffer: 6,

    // getItemSize: row => {
        // if (rowHeights.has(row.key)) {
            // return rowHeights.get(row.key)!;
        // }
        // return +props.itemHeight;
    // },
});

function updateHeight(pScrollItem: any, pVnode: VNode<HTMLElement>) {
    return;
    if (pScrollItem.item == null || rowHeights.has(pScrollItem.item.key)) {
        return;
    }
    if (pScrollItem.item.isLoading) {
        return;
    }
    const height = pVnode.el?.firstElementChild?.clientHeight;
    if (height) {
        if (rowHeights.has(pScrollItem.item.key)) { return; }
        rowHeights.set(pScrollItem.item.key, height);
        pScrollItem.updateSize(height);
    }
}

function handleExpandClick(pEvent: MouseEvent, pRow) {
    if (pRow.hasNodes) {
        pEvent.preventDefault();
        pEvent.stopPropagation();
        if (pRow.expanded) {
            pRow.collapse();
        } else {
            pRow.expand();
        }
    }
}

function getDisplayValue(pRow: any) {
    if (props.field) {
        return pRow[props.field];
    } else {
        return '';
    }
}

function handleRowClick(pItem) {
    if (props.onNodeClick) {
        props.onNodeClick(pItem);
    } else {
        props.dataObject.setCurrentIndex(pItem.index);
        emit('nodeClick', pItem);
    }
}

function onContextMenu(pEvent, pRow) {
    pEvent.preventDefault();
    currentContextMenuRow.value = pRow;
    props.dataObject.setCurrentIndex(pRow.index);
    cX.value = pEvent.x - 2;
    cY.value = pEvent.y - 2;
    if (dropdownRef.value && dropdownRef.value.isOpen) {
        dropdownRef.value.close().then(() => {
            dropdownRef.value?.open();
        });
    } else {
        dropdownRef.value?.open();
    }
}

const virtualTarget = computed(() => {
    return {
        getBoundingClientRect: () => ({
            width: 0,
            height: 0,
            top: cY.value,
            right: cX.value,
            bottom: cY.value,
            left: cX.value,
        })
    };
});

function getRange(pLevel: number) {
    return Array.from({ length: pLevel }, (_, index) => index);
}

onMounted(() => {
    if (props.loadDataObject) {
        props.dataObject.load();
    }
});

</script>

<style scoped>

.o365-treelist {
    flex: 1;
    height: 100%;
    overflow-y: auto;
}
.o365-treelist-viewport {
    position: relative;
    display: flex;
    user-select: none;
}

.o365-treelist-item {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: stretch;
    flex-wrap: nowrap;
}
.o365-treelist-item.acitve {
    animation: treelist-item-selected 500ms ease-out;
    background-color: rgba(var(--bs-primary-rgb), .25);
}
.o365-treelist-item:hover  {
    background-color: rgba(var(--bs-primary-rgb), .3);
}

@keyframes treelist-item-selected{
    from { background-color: rgba(var(--bs-primary-rgb), .75); }
}

.o365-treelist-item .hover-icons {
    display: none;
    flex-grow: 1;
    align-items: center;
    flex-direction: row;
    align-self: stretch;
    flex-wrap: nowrap;
}

.o365-treelist-item:hover .hover-icons {
    display: flex;
}

.o365-treelist-item-indent {
    display: inline-block;
    height: 100%;
}

.o365-treelist-item-indent-guide {
    border-right: 1px solid transparent;
    box-sizing: border-box;
    display: inline-block;
    height: 100%;
}

.o365-treelist-viewport.indent-lines-on-hover:hover .o365-treelist-item-indent-guide {
    border-color: var(--bs-border-color);
}
.o365-treelist-viewport.indent-lines .o365-treelist-item-indent-guide {
    border-color: var(--bs-border-color);
}
</style>